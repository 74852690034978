import { postMessage } from 'src/services/message-service/message-service';
import { IPost } from 'src/services/post-service/post-service.interface';
import { IUser } from 'src/services/user-service/user-service.interface';

export const handleRequestSend = async (
  loggedinUser: IUser,
  users: IUser[],
  post: any,
  type?: string
) => {
  const reqUserData = users?.[0];
  const messageBodyLine1 = {
    type: 'text',
    content: `Hi ${reqUserData?.name}, ${loggedinUser?.name} has requested your response on the post mentioned below-`,
  };
  const messageBodyLine2: any = {
    type: 'link',
  };

  if (type === 'POST') {
    messageBodyLine2.content = (post as IPost)?.plainText;

    messageBodyLine2.url = `/post?postId=${(post as IPost)._id}`;
  } else {
    messageBodyLine1.content = `Hi ${reqUserData?.name}, ${loggedinUser?.name} has requested your presence on the event mentioned below -`;
    messageBodyLine2.content = post?.eventName;
    messageBodyLine2.url = `/events?eventId=${post.id}`;
  }
  let payload1 = {
    content: messageBodyLine1,
    userId: reqUserData?._id,
  };
  let payload2 = {
    content: messageBodyLine2,
    userId: reqUserData?._id,
  };
  try {
    await postMessage(payload1);
    await postMessage(payload2);
  } catch (error) {
    console.error(error);
  }
};
