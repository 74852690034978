import classNames from 'classnames';
import Modal from 'react-bootstrap/Modal';
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
} from 'reactstrap';
import { createRef, useContext, useEffect, useRef, useState } from 'react';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Select from 'react-select';
import styles from './CreatePost.module.css';
import { MenuContext } from 'src/context/menu.context';
import { uploadAttachment } from 'src/services/attachment-service/attachment-service.client';
import { createPost, postAnswer } from 'src/services/post-service/post-service';
import {
  IAttachment,
  IComment,
  IPost,
} from 'src/services/post-service/post-service.interface';
import { attchmentList } from '../common';
import { FileList } from '../file-list';
import { LoadingLottie } from '../lotties/loading';
import { SuccessLottie } from '../lotties/success';
import { ValidationMessage } from '../validation-message';
import {
  attachmentToFileMap,
  formatExpertiseToOptions,
  formatMenuToOptions,
} from './create-post.helper';
import ReactQuill, { Quill, UnprivilegedEditor } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { IUser } from 'src/services/user-service/user-service.interface';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCaretDown,
  faCompress,
  faExpand,
  faGlobe,
  faUserTie,
} from '@fortawesome/free-solid-svg-icons';
import { dataUrlToFile, resolveBEAsset } from 'src/utils/urls';
import ImageCompress from 'quill-image-compress';
import { fetchUserDetails } from 'src/services/user-service/user-servicec';
import CreateSignatureModal from '../createSignature/CreateSignatureModal';
import CategoryRequestSentModal from '../createSignature/CategoryRequestSentModal';

Quill.register('modules/imageCompress', ImageCompress);

type ICreatePostProps = {
  show: boolean;
  onHide: () => void;
  isAnswer?: boolean;
  isComment?: boolean;
  ques?: IPost;
  setRefresh?: (post: IPost) => void;
  onCreateComment?: (post: IComment) => void;
  onUpdatePost?: (post: IPost) => void;
  onUpdateComment?: (comment: IComment) => void;
  initialPostData?: IPost;
  initialCommentData?: IComment;
  openCreateCategoryCategoryModal?: () => void;
};

const DropdownIndicator = () => null;

const HelpToolTip = () => (
  <OverlayTrigger
    placement="left"
    overlay={
      <Tooltip id={`tooltip`} className={styles.tooltipOverlay}>
        Tags are nonetheless, but Categories eg: Income-Tax, GST, Civil &
        Criminal Law ...etc. “<strong>+ Create Category</strong>” to add new
        Tag.
      </Tooltip>
    }
  >
    <i
      className="fa fa-question-circle-o"
      style={{ fontSize: '24px', margin: '0.5rem' }}
    />
  </OverlayTrigger>
);

const hasFileError = (files: Array<File>) => {
  if (files.length > 5) {
    return 'Only 5 files allowed';
  }

  const sizeLimitBytes = 10 * 1024 * 1024;
  const exceedsSizeLimit = files.some((file) => file.size > sizeLimitBytes);
  if (exceedsSizeLimit) {
    return 'Only 10 MB per file is allowed';
  }

  return false;
};

export default function CreatePost(props: ICreatePostProps) {
  const { menu } = useContext(MenuContext);
  const {
    isAnswer = false,
    ques,
    isComment = false,
    initialCommentData,
    initialPostData,
    onUpdateComment,
    onUpdatePost,
    openCreateCategoryCategoryModal,
  } = props;

  const profileId = localStorage.getItem('id') || '';
  const [user, setUser] = useState<IUser | null>(null);

  const getUserDetails = async (userId: string) => {
    try {
      const userRes = await fetchUserDetails(userId);
      setUser(userRes);
    } catch (error) {
      console.error('Failed to fetch user details:', error);
    }
  };

  useEffect(() => {
    if (profileId) {
      getUserDetails(profileId);
    }
  }, [profileId]);

  const [isQues, setIsQues] = useState(true);
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [desc, setDesc] = useState('');
  const [selectedOption, setSelectedOption] = useState<any>([]);
  const [selectedFiles, setSelectedFiles] = useState<Array<File>>([]);
  const [validated, setValidated] = useState(false);
  const [invalidTag, setInvalidTag] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [professionalOnly, setProfessionalOnly] = useState(false);

  const inputRefs = useRef([]);
  const menuOptions = formatMenuToOptions(menu);

  const setInitialPostData = async () => {
    if (initialPostData) {
      setLoading(true);
      const post = initialPostData;
      setIsQues(post.type !== 'WORK');
      setTitle(post.title);
      setDesc(post.content || '');
      setProfessionalOnly(post.professionalOnly || false);
      const options = formatExpertiseToOptions(
        post.expertise || [],
        menuOptions
      );
      const files = await attachmentToFileMap(post?.attachments);
      setSelectedOption(options);
      setSelectedFiles(files);
      setLoading(false);
    }
  };

  const setInitialCommentData = async () => {
    if (initialCommentData) {
      setLoading(true);
      const comment = initialCommentData;
      setDesc(comment.content || '');
      const files = await attachmentToFileMap(comment?.attachments);
      setSelectedFiles(files);
      setLoading(false);
    }
  };
  const currentUrl = window.location.href;
  useEffect(() => {
    setInitialPostData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialPostData]);

  useEffect(() => {
    setInitialCommentData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialCommentData]);

  const resetForm = (form: HTMLFormElement) => {
    if (form) {
      form.reset();
    }
    setIsQues(true);
    setTitle('');
    setText('');
    setDesc('');
    setSelectedOption([]);
    setSelectedFiles([]);
    setValidated(false);
    setInvalidTag(false);
    setLoading(false);
    setSuccess(false);
  };

  useEffect(() => {
    return () => {
      if (!props.show) {
        setIsQues(true);
        setTitle('');
        setText('');
        setDesc('');
        setSelectedFiles([]);
      }
    };
  }, [props.show]);

  useEffect(() => {
    if (selectedOption.length > 0) {
      setInvalidTag(false);
    }
  }, [selectedOption]);

  if (inputRefs.current.length !== attchmentList.length) {
    // @ts-ignore
    inputRefs.current = Array(attchmentList.length)
      .fill(0)
      .map((_, index) => inputRefs.current[index] || createRef());
  }

  const resizeImage = (file: File, targetSizeKB: number): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event: ProgressEvent<FileReader>) => {
        const img = new Image();
        img.src = event.target?.result as string;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

          const maxSize = targetSizeKB * 1024;
          let width = img.width;
          let height = img.height;

          const ratio = width / height;
          if (width > height) {
            width = Math.min(Math.sqrt(maxSize / ratio), img.width);
            height = width / ratio;
          } else {
            height = Math.min(Math.sqrt(maxSize * ratio), img.height);
            width = height * ratio;
          }

          canvas.width = width;
          canvas.height = height;

          ctx.drawImage(img, 0, 0, width, height);
          const resizedDataUrl = canvas.toDataURL(file.type);
          resolve(resizedDataUrl);
        };
        img.onerror = (error) => {
          reject(error);
        };
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    if (files && files.length) {
      try {
        const resizedFiles: File[] = [];

        for (let i = 0; i < files.length; i++) {
          const file = files[i];

          if (file.type.startsWith('image/')) {
            const resizedDataUrl = await resizeImage(file, 1024);
            const resizedFile = await dataUrlToFile(resizedDataUrl, file.name);

            resizedFiles.push(resizedFile);
          } else {
            setSelectedFiles((prevFiles) => [...prevFiles, file]);
          }
        }

        setSelectedFiles((prevFiles) => [...prevFiles, ...resizedFiles]);
      } catch (error) {
        console.error('Error resizing images:', error);
      }
    }
  };

  const handleButtonClick = (index: number) => {
    if (inputRefs.current[index]) {
      //@ts-ignore
      inputRefs.current[index].current.click();
    }
  };

  function deSelectAttachment(fileIndex: number): void {
    setSelectedFiles([
      ...selectedFiles.filter((file, index) => index !== fileIndex),
    ]);
  }

  async function handlePost(event: any): Promise<void> {
    const form = event.currentTarget;

    if (
      form.checkValidity() === false ||
      hasFileError(selectedFiles) ||
      !(isQues || desc.length || selectedFiles.length)
    ) {
      setInvalidTag(true);
      event.preventDefault();
      event.stopPropagation();
    } else {
      setInvalidTag(false);
      setLoading(true);
      try {
        if (isAnswer || isComment) {
          const existingAttachments: Array<IAttachment> = [];
          let newSelectedFile: Array<File> = [];
          if (initialCommentData?.attachments) {
            selectedFiles.forEach((file) => {
              const existingAttachment = initialCommentData?.attachments.find(
                (attachment) => attachment.originalName === file.name
              );
              if (existingAttachment) {
                existingAttachments.push(existingAttachment);
              } else {
                newSelectedFile.push(file);
              }
            });
          } else {
            newSelectedFile = selectedFiles;
          }

          const attachmentsArr = await uploadAttachment(newSelectedFile);

          const answer = await postAnswer(
            ques?._id as string,
            desc,
            [...existingAttachments, ...attachmentsArr],
            initialCommentData
          );
          if (initialCommentData?._id) {
            onUpdateComment?.(answer);
          }
          props?.onCreateComment?.(answer);
        } else {
          if (selectedOption) {
            const existingAttachments: Array<IAttachment> = [];
            let newSelectedFile: Array<File> = [];
            if (initialPostData?.attachments) {
              selectedFiles.forEach((file) => {
                const existingAttachment = initialPostData?.attachments.find(
                  (attachment) => attachment.originalName === file.name
                );
                if (existingAttachment) {
                  existingAttachments.push(existingAttachment);
                } else {
                  newSelectedFile.push(file);
                }
              });
            } else {
              newSelectedFile = selectedFiles;
            }

            const attachmentsArr = await uploadAttachment(newSelectedFile);
            const newPost = await createPost(
              isQues ? 'QUESTION' : 'WORK',
              title,
              text,
              desc,
              selectedOption,
              professionalOnly,
              [...existingAttachments, ...attachmentsArr],
              initialPostData
            );

            if (initialPostData?._id) {
              onUpdatePost?.(newPost);
            }
            props?.setRefresh?.(newPost);
          }
        }
        setLoading(false);
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
          props.onHide();
          resetForm(form);
        }, 2500);
      } catch (e) {
        console.log('Post failed');
        resetForm(form);
      }
    }
    setValidated(true);
  }

  const fileError = hasFileError(selectedFiles);

  const [isMaximized, setIsMaximized] = useState(false);

  const toggleModalSize = () => {
    setIsMaximized(!isMaximized);
  };

  const modules = {
    toolbar: [
      [
        'bold',
        'italic',
        'underline',
        { list: 'ordered' },
        { list: 'bullet' },
        { align: 'center' },
        { align: 'right' },
        'link',
        'image',
      ],
    ],
    clipboard: {
      matchVisual: false,
    },
    imageCompress: {
      quality: 0.4,
      maxWidth: 700,
      maxHeight: 700,
      imageType: 'image/jpeg',
      debug: true,
      suppressErrorLogging: false,
      insertIntoEditor: undefined,
    },
  };

  const formats = [
    'size',
    'bold',
    'italic',
    'align',
    'underline',
    'blockquote',
    'list',
    'bullet',
    'link',
    'image',
  ];
  const [showDropdown, setShowDropdown] = useState(false);
  const [inputValue, setInputValue] = useState<string>('');
  const [isCreateModalOpen, setCreateModalOpen] = useState(false);

  const [isCategoryRequestSentModalOpen, setCategoryRequestSentModalOpen] =
    useState(false);
  const handleCreateSignatureSuccess = () => {
    setCreateModalOpen(false);
    setCategoryRequestSentModalOpen(true);
  };
  const getOptionsWithCreateNew = () => {
    const noMatchFound = menuOptions.every(
      (option) => !option.label.toLowerCase().includes(inputValue.toLowerCase())
    );

    if (noMatchFound && inputValue.trim()) {
      return [
        ...menuOptions,
        {
          label: `Create New Category "${inputValue}"`,
          value: 'create-new-category',
          isCreateNew: true, // Custom flag for new category
        },
      ];
    }
    return menuOptions;
  };
  return (
    <>
      <Modal
        {...props}
        size={isMaximized ? 'xl' : 'lg'}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        className={styles['create-post-moadl']}
      >
        {!loading && !success && (
          <>
            <Modal.Header closeButton className={styles['modal-header-mobile']}>
              <Modal.Title
                id="contained-modal-title-vcenter"
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                {user && (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                      className={styles['createSign-modal-header-img']}
                      src={resolveBEAsset(user?.picture?.path || '')}
                      alt="userImage"
                    />
                    &nbsp;
                    {user?.isProfessional && !isComment && !isAnswer && (
                      <Dropdown
                        isOpen={showDropdown}
                        toggle={() => setShowDropdown(!showDropdown)}
                        className={styles['sort-dropdown-mobile']}
                      >
                        <DropdownToggle
                          style={{
                            display: 'flex',
                            background: 'none',
                            color: 'black',
                            alignItems: 'center',
                            border: 'none',
                            fontSize: '14px',
                          }}
                        >
                          <Button
                            onClick={() => {
                              setShowDropdown(true);
                            }}
                            className={styles['noit-professional-only']}
                          >
                            {professionalOnly ? (
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faUserTie}
                                  style={{ color: '#1a1a1a' }}
                                />
                                &nbsp; Professionals Only &nbsp;{' '}
                                <FontAwesomeIcon icon={faCaretDown} />
                              </div>
                            ) : (
                              <>
                                <FontAwesomeIcon
                                  icon={faGlobe}
                                  style={{ color: '#1a1a1a' }}
                                />
                                &nbsp;Public (All Users) &nbsp;{' '}
                                <FontAwesomeIcon icon={faCaretDown} />
                              </>
                            )}
                          </Button>
                        </DropdownToggle>
                        <DropdownMenu>
                          <>
                            <DropdownItem
                              onClick={() => setProfessionalOnly(false)}
                              style={{
                                whiteSpace: 'break-spaces',
                                lineHeight: 'initial',
                              }}
                            >
                              <span
                                style={{
                                  fontSize: '12px',
                                  fontWeight: '500',
                                  textDecoration: 'underline',
                                }}
                              >
                                Public (All Users)
                              </span>
                              <br />
                              <span style={{ fontSize: '12px' }}>
                                The post shall be shared accross globally to all
                                the platform users.
                              </span>
                            </DropdownItem>
                            <DropdownItem
                              onClick={() => setProfessionalOnly(true)}
                              style={{
                                whiteSpace: 'break-spaces',
                                lineHeight: 'initial',
                              }}
                            >
                              <span
                                style={{
                                  fontSize: '12px',
                                  fontWeight: '500',
                                  textDecoration: 'underline',
                                }}
                              >
                                Professionals Only
                              </span>
                              <br />
                              <span style={{ fontSize: '12px' }}>
                                The post shall be restricted to professional
                                members of the commmunity.
                              </span>
                            </DropdownItem>
                          </>
                        </DropdownMenu>
                      </Dropdown>
                    )}
                  </div>
                )}
                {window.innerWidth > 700 && (
                  <div
                    onClick={toggleModalSize}
                    style={{
                      marginRight: '10px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {isMaximized ? (
                      <FontAwesomeIcon
                        icon={faCompress}
                        className={styles.maxMinModalBtn}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faExpand}
                        className={styles.maxMinModalBtn}
                      />
                    )}
                  </div>
                )}
              </Modal.Title>
            </Modal.Header>
          </>
        )}
        <Modal.Body>
          {success && <SuccessLottie height={410} />}
          {loading && <LoadingLottie height={410} />}
          {!success && !loading && (
            <>
              {!isAnswer && !isComment && (
                <div className={styles.modalHeaderButtonsWrapper}>
                  <button
                    className={classNames(styles.modalHeaderQuesbtn, {
                      [styles.modalHeaderQuesActive]: isQues,
                    })}
                    onClick={() => setIsQues(true)}
                  >
                    Ask Question
                  </button>

                  <button
                    className={classNames(styles.modalHeaderQuesbtn, {
                      [styles.modalHeaderProfActive]: !isQues,
                    })}
                    onClick={() => setIsQues(false)}
                  >
                    Publish Insight
                  </button>
                </div>
              )}

              <Form noValidate validated={validated} onSubmit={handlePost}>
                <div
                  className={classNames(
                    isAnswer || isComment
                      ? styles['height-200']
                      : styles['height-100']
                  )}
                >
                  <div className={styles['quill-deitor-container']}>
                    <FormGroup controlId="quillEditor">
                      <div className={styles['quill-deitor-wrapper']}>
                        {(isAnswer || isComment) && (
                          <ReactQuill
                            theme="snow"
                            value={desc}
                            modules={modules}
                            formats={formats}
                            onChange={setDesc}
                            className={styles['text-editor-quill']}
                            placeholder={
                              isComment
                                ? 'Comment here ..'
                                : 'Type your answer ..'
                            }
                            style={{
                              height: isMaximized ? '300px' : '150px',
                              maxHeight: isMaximized ? '300px' : '150px',
                            }}
                          />
                        )}
                        {!isAnswer && !isComment && isQues && (
                          <ReactQuill
                            theme="snow"
                            value={desc}
                            modules={modules}
                            formats={formats}
                            onChange={(
                              value: string,
                              delta: any,
                              source: any,
                              editor: UnprivilegedEditor
                            ) => {
                              setText(editor.getText());
                              setDesc(value);
                            }}
                            className={styles['text-editor-quill']}
                            placeholder={
                              window.innerWidth > 700
                                ? `Start your question with “What”, “How”, “Why”, … etc\n
                          Tips for getting a quick response:
                          • Make sure the question has not been asked already
                          • Keep your queries short, simple, and to the point
                          • Double-check grammar and spelling
                          • Use proper tags to target the correct group of professionals`
                                : `Start with “What”, “How”, “Why”, … etc`
                            }
                            style={{
                              height: isMaximized ? '300px' : '185px',
                              maxHeight: isMaximized ? '300px' : '185px',
                              minHeight: isMaximized ? '300px' : '185px',
                            }}
                          />
                        )}
                        {!isAnswer && !isComment && !isQues && (
                          <ReactQuill
                            theme="snow"
                            value={desc}
                            modules={modules}
                            formats={formats}
                            onChange={(
                              value: string,
                              delta: any,
                              source: any,
                              editor: UnprivilegedEditor
                            ) => {
                              setText(editor.getText());
                              setDesc(value);
                            }}
                            className={styles['text-editor-quill']}
                            placeholder={
                              window.innerWidth > 700
                                ? `Say something insightful about “____”\n
                          Tips for getting a better reach:
                          • Make sure the insight has not been shared already
                          • Double-check grammar and spelling
                          • Use proper tags to target the correct group of the audience`
                                : `Say something insightful about “____”`
                            }
                            style={{
                              height: isMaximized ? '300px' : '185px',
                              maxHeight: isMaximized ? '300px' : '185px',
                              minHeight: isMaximized ? '300px' : '185px',
                            }}
                          />
                        )}
                      </div>

                      {invalidTag && (
                        <div className={styles['quill-validation-message']}>
                          <ValidationMessage>
                            This field is required.
                          </ValidationMessage>
                        </div>
                      )}
                    </FormGroup>
                  </div>
                </div>

                <div className={styles['filesContainer']}>
                  <FileList
                    files={selectedFiles || []}
                    onClose={(index) => deSelectAttachment(index)}
                  />
                </div>

                {fileError && (
                  <ValidationMessage>{fileError}</ValidationMessage>
                )}
                {!isAnswer && !isComment ? (
                  <Form.Group>
                    <Select
                      name="tag"
                      required
                      isMulti
                      components={{
                        DropdownIndicator,
                        IndicatorsContainer: HelpToolTip,
                      }}
                      className={styles['add-tag']}
                      value={selectedOption}
                      onChange={(option) => {
                        if (Array.isArray(option)) {
                          const createOption = option.find(
                            (opt) => opt.value === 'create-new-category'
                          );
                          if (createOption) {
                            if (window.innerWidth > 700) {
                              props.onHide();
                              setCreateModalOpen(true);
                            }
                            if (
                              window.innerWidth < 700 &&
                              openCreateCategoryCategoryModal
                            ) {
                              openCreateCategoryCategoryModal();
                            }
                          } else {
                            setSelectedOption(option || []);
                          }
                        }
                      }}
                      placeholder="Add Tags *"
                      onInputChange={(value) => setInputValue(value)} // Update input value
                      options={getOptionsWithCreateNew()} // Use options with potential "Create New Category"
                      styles={{
                        multiValue: (base, state) => ({
                          ...base,
                          borderRadius: '12px',
                          padding: '0px 4px',
                        }),
                        multiValueRemove: (base, state) => ({
                          ...base,
                          ':hover': { background: 'unset', color: 'red' },
                        }),
                        valueContainer: (base) => ({
                          ...base,
                          height: '5rem',
                        }),
                        control: (base, state) => ({
                          ...base,
                          borderRadius: '12px !important',
                          fontSize: '14px !important',
                          ...(state.menuIsOpen
                            ? {
                                border: 'unset',
                                ...(invalidTag
                                  ? {
                                      boxShadow:
                                        '0 0 0 0.25rem rgba(var(--bs-danger-rgb),.25);',
                                    }
                                  : {
                                      ...(state.hasValue
                                        ? {
                                            boxShadow:
                                              '0 0 0 0.25rem rgba(var(--bs-success-rgb),.25)',
                                          }
                                        : {
                                            boxShadow:
                                              '0 0 0 0.25rem rgba(13,110,253,.25);',
                                          }),
                                    }),
                              }
                            : {
                                border:
                                  '1px solid rgb(230, 230, 230); !important',
                              }),
                        }),
                      }}
                    />

                    {invalidTag && (
                      <ValidationMessage>
                        This field is required.
                      </ValidationMessage>
                    )}
                  </Form.Group>
                ) : (
                  <></>
                )}

                <div className={styles['dashboard-search-attachment-list']}>
                  {currentUrl.includes('dashboard') &&
                    attchmentList.map((item, idx) => (
                      <Button
                        key={`Attachment_${item.text}`}
                        className={styles['attachment-btn']}
                        color="transparent"
                        onClick={() => handleButtonClick(idx)}
                      >
                        <input
                          type="file"
                          accept={`${item.accept}`}
                          style={{ display: 'none' }}
                          ref={inputRefs.current[idx]}
                          onChange={handleImageChange}
                          multiple
                        />
                        <img src={item.icon} alt={item.text} />
                        {item.text}
                      </Button>
                    ))}
                  {currentUrl.includes('post') &&
                    attchmentList
                      ?.filter((item) => item.text !== 'Video')
                      .map((item, idx) => (
                        <Button
                          key={`Attachment_${item.text}`}
                          className={styles['attachment-btn']}
                          color="transparent"
                          onClick={() => handleButtonClick(idx)}
                          disabled={!user?.isProfessional && idx !== 0}
                        >
                          <input
                            type="file"
                            accept={`${item.accept}`}
                            style={{ display: 'none' }}
                            ref={inputRefs.current[idx]}
                            onChange={handleImageChange}
                            multiple
                          />
                          <img src={item.icon} alt={item.text} />
                          {item.text}
                        </Button>
                      ))}

                  <div className={styles.postBtnWrapper}>
                    {currentUrl.includes('/dashboard') ? (
                      <button type="submit" className={styles.postBtn}>
                        {initialCommentData || initialPostData
                          ? 'Update'
                          : isQues
                          ? 'Post Question'
                          : 'Publish Insight'}
                      </button>
                    ) : (
                      <button type="submit" className={styles.postBtn}>
                        {initialCommentData || initialPostData
                          ? 'Update'
                          : 'Post'}
                      </button>
                    )}
                  </div>
                </div>
              </Form>
            </>
          )}
        </Modal.Body>
      </Modal>
      <CreateSignatureModal
        isOpen={isCreateModalOpen}
        onClose={() => {
          setCreateModalOpen(false);
        }}
        onCreate={handleCreateSignatureSuccess}
        defaultCategory={inputValue}
      />

      <CategoryRequestSentModal
        isOpen={isCategoryRequestSentModalOpen}
        onClose={() => {
          setCategoryRequestSentModalOpen(false);
        }}
      />
    </>
  );
}
