import React, { useState, useEffect } from 'react';
import Layout from 'src/layouts/layout';
import DashboardSearch from 'src/components/dashboard/dashboardSearch';
import DashboardList from 'src/components/dashboard/dashbaordList';
import styles from './dashboard.module.css';
import { IPost } from 'src/services/post-service/post-service.interface';
import BottomBar from 'src/components/bottombar/BottomBar';
import { Button } from 'reactstrap';
import CreatePost from 'src/components/create-post';
import EventLogo from 'src/components/events/EventLogo';
import SignupModal from 'src/components/stepper/SignupModal';
import { Helmet } from 'react-helmet-async';
import {
  IMembership,
  IUser,
} from 'src/services/user-service/user-service.interface';
import {
  fetchMembership,
  fetchUserDetails,
} from 'src/services/user-service/user-servicec';

import NewMembership from 'src/components/profile/new-membership';
import AppPopUp from 'src/components/bottombar/AppPopUp';
import CreateSignatureModal from 'src/components/createSignature/CreateSignatureModal';
import CategoryRequestSentModal from 'src/components/createSignature/CategoryRequestSentModal';

const Dashboard = () => {
  const [refresh, setRefresh] = useState<IPost>();
  const isMobile = window?.innerWidth <= 700;
  const [modalShow, setModalShow] = useState(false);
  const [signupModal, setSignupModal] = useState(false);
  const token = localStorage.getItem('token');

  const handleCreatePost = () => {
    setModalShow(true);
  };

  const profileId = localStorage.getItem('id') || '';
  const [user, setUser] = useState<IUser | null>(null);

  const getUserDetails = async (userId: string) => {
    try {
      const userRes = await fetchUserDetails(userId);
      setUser(userRes);
    } catch (error) {
      console.error('Failed to fetch user details:', error);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [membership, setMembership] = useState<IMembership[]>([]);
  const [showAddMembership, setShowAddMembership] = useState(false);

  const getMembership = async (userId: string) => {
    try {
      const res = await fetchMembership(userId);
      setMembership(res.data);
      setShowAddMembership(res.data.length === 0);
    } catch (error) {
      console.error('Failed to fetch membership details:', error);
    }
  };

  useEffect(() => {
    if (profileId) {
      getUserDetails(profileId);
      getMembership(profileId);
    }
  }, [profileId]);

  const [categoryName, setCategoryName] = useState<string>('');

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  const [isCategoryRequestSentModalOpen, setCategoryRequestSentModalOpen] =
    useState(false);

  const handleCreateSignatureSuccess = (defaultValue: string) => {
    setCategoryName(defaultValue);
    setIsCreateModalOpen(false);
    setCategoryRequestSentModalOpen(true);
  };

  const openCreateCategoryCategoryModal = () => {
    setModalShow(false);
    setIsCreateModalOpen(true);
  };
  return (
    <>
      <Helmet>
        <title>White Collar Professional | Home</title>
        <meta
          name="description"
          content="Professional platform for networking, career growth, and knowledge sharing, offering discussion boards, and resources to connect, learn, and advance their professional career."
        />
        <meta
          name="keywords"
          content="WCP community, White Collar Professional, Charted accountant, Company secretary, Cost accountant, Advocate, Lawyer, Legal, Law, Professionals, Carrer Growth, Knowledge, Development, Skills, Queriers, Insights, Articles, Doubts"
        />

        <link rel="canonical" href="/dashboard" />
      </Helmet>

      <Layout contentBordered>
        <div className={styles['wc-dashboard']}>
          <DashboardSearch setRefresh={setRefresh} />
          <DashboardList refresh={refresh} />
        </div>
        {isMobile && (
          <>
            <div
              onClick={() =>
                !token ? setSignupModal(true) : handleCreatePost()
              }
            >
              <Button className={styles['create-post-toggle-btn']}>
                <EventLogo fill="var(--grey-grey-00, #FFF)" />
              </Button>
            </div>
          </>
        )}
        {isMobile && <AppPopUp />}
        {modalShow && (
          <CreatePost
            show={modalShow}
            onHide={() => {
              setModalShow(false);
            }}
            setRefresh={setRefresh}
            openCreateCategoryCategoryModal={openCreateCategoryCategoryModal}
          />
        )}
        {isMobile && <BottomBar />}
        <SignupModal
          signupModal={signupModal}
          setSignupModal={setSignupModal}
        />
        <NewMembership
          showAddMembership={showAddMembership}
          setShowAddMembership={setShowAddMembership}
        />
        <CreateSignatureModal
          isOpen={isCreateModalOpen}
          onClose={() => {
            setIsCreateModalOpen(false);
          }}
          onCreate={handleCreateSignatureSuccess}
          defaultCategory={categoryName}
        />

        <CategoryRequestSentModal
          isOpen={isCategoryRequestSentModalOpen}
          onClose={() => {
            setCategoryRequestSentModalOpen(false);
          }}
        />
      </Layout>
    </>
  );
};

export default Dashboard;
