import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import SearchBar from './searchSelect';
import {
  faLock,
  faPowerOff,
  faTrash,
  faUser,
  faUserPlus,
  faSignOut,
  faBell,
  faUserTie,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useEffect, useMemo, useState } from 'react';
import {
  Link,
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { resolveBEAsset } from 'src/utils/urls';
import ChangePasswordForm from './change-password';
import DeleteAccount from './delete-account';
import styles from './navbar.module.css';
import NotificationButton from './notification-button';
import { IUser } from 'src/services/user-service/user-service.interface';
import SignupModal from '../stepper/SignupModal';
import JobsModal from '../jobs/JobsModal';
import { useLocation } from 'react-router';
import { UserContext } from 'src/context/user.context';
import UpgradeToProfessional from '../upgrade-to-professional/upgradeToProfessional';
import classNames from 'classnames';
import EventsSearch from '../events/EventsSearch';
import HiringJobSearch from '../hiringJobs/HiringJobSearch';
import TrendingImage from '../utils/trendingImage';
import { fetchUserDetails } from 'src/services/user-service/user-servicec';
import NotificationSearch from 'src/pages/notification/NotificationSearch';
import { categoryMap } from './search.helper';
import TrendSearch from '../trend/TrendSearch';

interface NavbarProps {
  isSearchQuery?: any;
  setIsSearchQuery?: any;
  isfaded?: any;
  setIsFaded?: any;
}
const Navbar = ({
  isSearchQuery,
  setIsSearchQuery,
  isfaded,
  setIsFaded,
}: NavbarProps) => {
  const router = useNavigate();
  const userObj = JSON.parse(localStorage.getItem('user') || `{}`);
  const [user, setUser] = useState<IUser>(userObj);
  const [show, setShow] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [showDeleteAccount, setShowDeleteAccount] = useState(false);
  const token = localStorage.getItem('token');

  const onLogout = () => {
    localStorage.clear();
    router(`/`);
  };

  const currentUrl = window.location.href;
  const [searchParams] = useSearchParams();
  const view = searchParams.get('view');
  const [signupModal, setSignupModal] = useState(false);
  const handleLogoClick = () => {
    if (currentUrl.includes('/dashboard')) {
      const url = new URL(window.location.href);
      const params = new URLSearchParams(url.search);
      params.delete('metaDataId');
      const newUrl = params.toString()
        ? `${url.pathname}?${params.toString()}`
        : '/dashboard';
      window.location.href = newUrl;
      window.scrollTo(0, 0);
    } else {
      router('/dashboard');
    }
  };
  const [isOpen, setIsOpen] = useState(false);

  const [showDropdown, setShowDropdown] = useState(false);
  function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();

  const searchTerm = searchParams.get('searchQuery') || '';
  const [searchQuery, searchSearchQuery] = useState(searchTerm);
  const [notiName, setNotiName] = useState('');
  const [eventName, setEventName] = useState('');
  const [jobName, setJobName] = useState('');
  const [trendName, setTrendName] = useState('');

  const [isEventQuery, setIsEventQuery] = useState(false);
  const [isNotiQuery, setIsNotiQuery] = useState(false);
  const [isTrendQuery, setIsTrendQuery] = useState(false);
  const [isJobQuery, setIsJobQuery] = useState(false);
  const [isSearchBarQuery, setIsSearchBarQuery] = useState(false);

  useEffect(() => {
    if (
      isEventQuery ||
      isNotiQuery ||
      isTrendQuery ||
      isJobQuery ||
      isSearchBarQuery
    ) {
      setIsSearchQuery(true);
    } else {
      setIsSearchQuery(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEventQuery, isNotiQuery, isTrendQuery, isJobQuery, isSearchBarQuery]);

  const profileId = query.get('profileId');
  const loggedInUser = localStorage.getItem('id') || '';
  const { id } = useContext(UserContext);
  const [self, setSelf] = useState(loggedInUser === id);
  const handleMyView = (key: string) => {
    const urlParams = new URLSearchParams(searchParams);
    urlParams.set('profileId', id);
    urlParams.set('view', key);
    urlParams.delete('postId');

    router({
      pathname: '/profile',
      search: createSearchParams(urlParams).toString(),
    });
    setIsFaded(false);
  };
  const handleOpenMoblieDropdown = () => {
    setShowDropdown(true);
    setIsFaded(true);
  };
  const [profileUserName, setProfileUserName] = useState('');
  const getUserDetails = async (userId: string) => {
    const userRes = await fetchUserDetails(userId);
    setUser(userRes);
  };
  const getProfileUserDetails = async (userId: string) => {
    const userRes = await fetchUserDetails(userId);

    setProfileUserName(userRes?.name?.split(' ')[0]);
  };
  const [isBlinker, setIsBlinker] = useState(false);
  useEffect(() => {
    const interval = setInterval(() => {
      setIsBlinker((prev) => !prev);
    }, 1000);

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  useEffect(() => {
    if (profileId) {
      getProfileUserDetails(profileId);

      setSelf(loggedInUser === profileId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileId]);
  useEffect(() => {
    getUserDetails(loggedInUser);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  if (currentUrl.includes('/post') && window.innerWidth <= 700) {
    return null;
  }

  return (
    <>
      {window.innerWidth < 700 ? (
        <div
          className={classNames(
            isSearchQuery ? styles['wc-navSearch'] : styles['wc-nav']
          )}
        >
          {!isSearchQuery && (
            <div>
              {currentUrl.includes('/trending') ||
              currentUrl.includes('/update') ||
              currentUrl.includes('message') ||
              currentUrl.includes('view') ||
              currentUrl.includes('chatwindow') ? (
                <div
                  className={styles['logo-container']}
                  onClick={handleLogoClick}
                >
                  <img
                    src="/images/wc_logoMain.svg"
                    alt="wcplogo - White Collar Professional"
                    width={35}
                    height={35}
                  />
                </div>
              ) : (
                <div
                  className={styles['logo-container']}
                  onClick={() => router('/trending')}
                >
                  <div style={{ position: 'absolute', height: '30px' }}>
                    <TrendingImage fill="#333" height={32} width={32} />
                    {isBlinker && <p className={styles.trendsBilnkerMobile} />}
                  </div>
                </div>
              )}
            </div>
          )}

          {currentUrl.includes('/notification') ? (
            <NotificationSearch
              notiName={notiName}
              setNotiName={setNotiName}
              isNotiQuery={isNotiQuery}
              setIsNotiQuery={setIsNotiQuery}
            />
          ) : currentUrl.includes('/events') ? (
            <EventsSearch
              eventName={eventName}
              setEventName={setEventName}
              isEventQuery={isEventQuery}
              setIsEventQuery={setIsEventQuery}
            />
          ) : currentUrl.includes('/jobs') ? (
            <HiringJobSearch
              jobName={jobName}
              setJobName={setJobName}
              isJobQuery={isJobQuery}
              setIsJobQuery={setIsJobQuery}
            />
          ) : currentUrl.includes('/update') ? (
            <div className={styles['noti-searchbar']}>Notifications</div>
          ) : currentUrl.includes('/message') ? (
            <div className={styles['noti-searchbar']}>Messages</div>
          ) : currentUrl.includes('/trending') ? (
            <TrendSearch
              trendName={trendName}
              setTrendName={setTrendName}
              isTrendQuery={isTrendQuery}
              setIsTrendQuery={setIsTrendQuery}
            />
          ) : view === 'published-works' ? (
            <div className={styles['noti-searchbar']}>
              {profileId && profileId !== id && profileUserName
                ? `${profileUserName}'s Published Work`
                : 'Published Work'}
            </div>
          ) : view === 'answers' ? (
            <div className={styles['noti-searchbar']}>
              {profileId && profileId !== id && profileUserName
                ? `${profileUserName}'s Answers / Comments`
                : 'Answers / Comments'}
            </div>
          ) : view === 'questions' ? (
            <div className={styles['noti-searchbar']}>
              {profileId && profileId !== id && profileUserName
                ? `${profileUserName}'s Questions`
                : 'Questions'}
            </div>
          ) : view === 'pins' ? (
            <>
              <div className={styles['noti-searchbar']}>Pins</div>
            </>
          ) : view === 'networks' ? (
            <div className={styles['noti-searchbar']}>
              {profileId && profileId !== id && profileUserName
                ? `${profileUserName}'s ${' '}  Network`
                : 'Network'}
            </div>
          ) : (
            <SearchBar
              searchQuery={searchQuery}
              searchSearchQuery={searchSearchQuery}
              searchTerm={searchTerm}
              isSearchBarQuery={isSearchBarQuery}
              setIsSearchBarQuery={setIsSearchBarQuery}
            />
          )}
          {!isSearchQuery && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                gap: '12px',
                marginRight: '4px',
              }}
            >
              {' '}
              {!currentUrl.includes('message') && (
                <div onClick={() => router('/message')}>
                  <img
                    src="/images/message-mobile.svg"
                    alt="messagesLogo"
                    height={30}
                  />
                </div>
              )}
              <Dropdown
                isOpen={showDropdown}
                toggle={() => {
                  setShowDropdown(!showDropdown);
                }}
                className={styles['sort-dropdown-mobile']}
                onBlur={() => setIsFaded(false)}
              >
                <DropdownToggle
                  style={{ background: 'none', border: 'none', padding: 0 }}
                >
                  <div
                    onClick={() =>
                      !token ? setSignupModal(true) : handleOpenMoblieDropdown()
                    }
                  >
                    <img
                      src={
                        user?.picture?.path
                          ? resolveBEAsset(user?.picture?.path || '')
                          : '/images/defaultProfilePic.svg'
                      }
                      height={35}
                      width={35}
                      style={{ borderRadius: '50%', margin: '2px' }}
                      alt="user profile - White Collar Professional"
                    />
                  </div>
                </DropdownToggle>
                <DropdownMenu hidden={signupModal}>
                  <DropdownItem>
                    <Button
                      onClick={() => router(`/profile?profileId=${user?._id}`)}
                      color="transparent"
                      className="option-item"
                    >
                      <span className="floatingIcon">
                        <FontAwesomeIcon icon={faUser} />
                      </span>
                      &nbsp; &nbsp;My Profile
                    </Button>
                  </DropdownItem>

                  {categoryMap(self, user?.isProfessional).map(
                    (item, index) => (
                      <DropdownItem key={index}>
                        <Button
                          onClick={() => handleMyView(item.key)}
                          color="transparent"
                          className="option-item"
                        >
                          <img alt="icon" src={item.icon} />
                          &nbsp;&nbsp;{item.title} &nbsp;
                          {item.key === 'questions'
                            ? `(${user?.postCount ? user?.postCount : 0})`
                            : item.key === 'published-works'
                            ? `(${user?.workCount ? user?.workCount : 0})`
                            : item.key === 'answers'
                            ? `(${
                                (user?.commentCount ?? 0) +
                                (user?.replyCount ?? 0)
                              })`
                            : item.key === 'networks'
                            ? `(${
                                (user?.followerCount ?? 0) +
                                (user?.followingCount ?? 0)
                              })`
                            : ''}
                        </Button>
                      </DropdownItem>
                    )
                  )}
                  {
                    <DropdownItem>
                      <Button
                        onClick={() => router('/update')}
                        color="transparent"
                        className="option-item"
                      >
                        <span className="floatingIcon">
                          <FontAwesomeIcon size="sm" icon={faBell} />
                        </span>
                        &nbsp; &nbsp;Notifications
                      </Button>
                    </DropdownItem>
                  }

                  {
                    <DropdownItem>
                      <div className={styles.navbarMobileDropdownSetting}>
                        Settings
                      </div>
                    </DropdownItem>
                  }
                  {!user?.isProfessional && (
                    <DropdownItem onClick={() => setShowUpgradeModal(true)}>
                      <Button color="transparent" className="option-item">
                        <FontAwesomeIcon icon={faUserTie} />
                        <span>&nbsp; Professional Registration</span>
                      </Button>
                    </DropdownItem>
                  )}
                  {
                    <DropdownItem>
                      <Button color="transparent" className="option-item">
                        <a
                          href="/technicalsupport/"
                          target="_blank"
                          rel="noreferrer"
                          style={{ textDecoration: 'none', color: 'inherit' }}
                        >
                          <img src="/images/help-support.svg" alt="Icon" />
                          &nbsp; Help & Support
                        </a>
                      </Button>
                    </DropdownItem>
                  }
                  {
                    <DropdownItem>
                      <Button color="transparent" className="option-item">
                        <a
                          href="https://www.whitecollarprofessional.com/legal-policies-wcp_community/"
                          target="_blank"
                          rel="noreferrer"
                          style={{ textDecoration: 'none', color: 'inherit' }}
                        >
                          <img src="/images/legal-policy.svg" alt="Icon" />
                          &nbsp; Legal Policy{' '}
                        </a>
                      </Button>
                    </DropdownItem>
                  }
                  {
                    <DropdownItem onClick={() => setShowDeleteAccount(true)}>
                      <Button color="transparent" className="option-item">
                        <FontAwesomeIcon size="sm" icon={faTrash} />
                        &nbsp;&nbsp;Delete Account
                      </Button>
                    </DropdownItem>
                  }
                  {
                    <DropdownItem onClick={() => setShowChangePassword(true)}>
                      <Button color="transparent" className="option-item">
                        <FontAwesomeIcon size="sm" icon={faLock} />
                        &nbsp;&nbsp;Change Password
                      </Button>{' '}
                    </DropdownItem>
                  }
                  {
                    <DropdownItem onClick={() => onLogout()}>
                      <Button color="transparent" className="option-item">
                        <FontAwesomeIcon size="sm" icon={faPowerOff} />
                        &nbsp;&nbsp;Logout
                      </Button>
                    </DropdownItem>
                  }
                </DropdownMenu>
              </Dropdown>
            </div>
          )}
        </div>
      ) : (
        <div className={styles['wc-nav']}>
          <div className={styles['logo-container']} onClick={handleLogoClick}>
            <img
              src="/images/wc_logoMain.svg"
              alt="wcplogo - White Collar Professional"
              style={{
                width: '50px',
                height: 'auto',
                padding: '10px',
                marginLeft: '10px',
              }}
            />
          </div>

          {currentUrl.includes('events') ? (
            <EventsSearch
              eventName={eventName}
              setEventName={setEventName}
              isEventQuery={isEventQuery}
              setIsEventQuery={setIsEventQuery}
            />
          ) : currentUrl.includes('trending') ? (
            <TrendSearch
              trendName={trendName}
              setTrendName={setTrendName}
              isTrendQuery={isTrendQuery}
              setIsTrendQuery={setIsTrendQuery}
            />
          ) : currentUrl.includes('notification') ? (
            <NotificationSearch
              notiName={notiName}
              setNotiName={setNotiName}
              isNotiQuery={isNotiQuery}
              setIsNotiQuery={setIsNotiQuery}
            />
          ) : currentUrl.includes('jobs') ? (
            <HiringJobSearch
              jobName={jobName}
              setJobName={setJobName}
              isJobQuery={isJobQuery}
              setIsJobQuery={setIsJobQuery}
            />
          ) : (
            <SearchBar
              searchQuery={searchQuery}
              searchSearchQuery={searchSearchQuery}
              searchTerm={searchTerm}
              isSearchBarQuery={isSearchBarQuery}
              setIsSearchBarQuery={setIsSearchBarQuery}
            />
          )}
          <div className={styles['nav-options']}>
            <div
              className={classNames(
                styles.navbarButtonTextWrapper,
                currentUrl.includes('dashboard')
                  ? styles['selectedButtonTextWrapper']
                  : ''
              )}
            >
              <Button
                color="transparent"
                className={classNames(
                  currentUrl.includes('dashboard')
                    ? styles['selectedButton']
                    : ''
                )}
              >
                <Link to="/dashboard">
                  <img
                    src="/images/home-mobile.svg"
                    alt="professionalLogo - WCP Community"
                    width={35}
                    height={35}
                    className={classNames(
                      currentUrl.includes('dashboard')
                        ? styles['selectedButtonImg']
                        : styles['buttonImg']
                    )}
                  />
                </Link>
              </Button>
              <p className={styles.navbarButtonText}>Home</p>
            </div>

            <div
              className={classNames(
                styles.navbarButtonTextWrapper,
                currentUrl.includes('search?searchType=Professional')
                  ? styles['selectedButtonTextWrapper']
                  : ''
              )}
            >
              <Button
                color="transparent"
                className={classNames(
                  currentUrl.includes('search?searchType=Professional')
                    ? styles['selectedButton']
                    : ''
                )}
              >
                <Link to="/search?searchType=Professional">
                  <img
                    src="/images/professioanlLogo.svg"
                    alt="professionalLogo - WCP Community"
                    width={35}
                    height={35}
                    className={classNames(
                      currentUrl.includes('search?searchType=Professional')
                        ? styles['selectedButtonImg']
                        : styles['buttonImg']
                    )}
                  />
                </Link>
              </Button>
              <p className={styles.navbarButtonText}>Professionals</p>
            </div>

            <div
              className={classNames(
                styles.navbarButtonTextWrapper,
                currentUrl.includes('/trending')
                  ? styles['selectedButtonTextWrapper']
                  : ''
              )}
            >
              <Button color="transparent" onClick={() => router('/trending')}>
                <div>
                  <img
                    src="/images/trendingIcon.svg"
                    alt="wcplogo - White Collar Professional"
                    width={35}
                    height={35}
                    className={classNames(
                      currentUrl.includes('/trending')
                        ? styles['selectedButtonImg']
                        : styles['buttonImg']
                    )}
                  />
                  {isBlinker && (
                    <div className={styles.trendsBilnkerWrapper}>
                      <p className={styles.trendsBilnker} />
                    </div>
                  )}
                </div>
              </Button>
              <p className={styles.navbarButtonText}>Trends</p>
            </div>

            <div
              className={classNames(
                styles.navbarButtonTextWrapper,
                currentUrl.includes('events')
                  ? styles['selectedButtonTextWrapper']
                  : ''
              )}
            >
              <Button
                color="transparent"
                className={classNames(
                  currentUrl.includes('events') ? styles['selectedButton'] : ''
                )}
              >
                <Link to="/events">
                  <img
                    src="/images/CalendarOutline.svg"
                    alt="event-logo - White Collar Professional"
                    className={classNames(
                      currentUrl.includes('events')
                        ? styles['selectedButtonImg']
                        : styles['buttonImg']
                    )}
                  />
                </Link>
              </Button>
              <p className={styles.navbarButtonText}>Events</p>
            </div>

            <div
              className={classNames(
                styles.navbarButtonTextWrapper,
                currentUrl.includes('notification')
                  ? styles['selectedButtonTextWrapper']
                  : ''
              )}
            >
              <Button
                color="transparent"
                className={classNames(
                  currentUrl.includes('notification')
                    ? styles['selectedButton']
                    : ''
                )}
              >
                <Link to="/notification">
                  <img
                    src="/images/marketing.svg"
                    alt="notification-logo - White Collar Professional"
                    height={35}
                    width={35}
                    className={classNames(
                      currentUrl.includes('notification')
                        ? styles['selectedButtonImg']
                        : styles['buttonImg']
                    )}
                  />
                </Link>
              </Button>
              <p className={styles.navbarButtonText}>Updates</p>
            </div>

            <div
              className={classNames(
                styles.navbarButtonTextWrapper,
                currentUrl.includes('jobs')
                  ? styles['selectedButtonTextWrapper']
                  : ''
              )}
            >
              <Button
                color="transparent"
                className={classNames(
                  currentUrl.includes('/jobs') ? styles['selectedButton'] : ''
                )}
              >
                <Link to="/jobs">
                  <img
                    src="/images/briefcase.svg"
                    alt="notification-logo - White Collar Professional"
                    className={classNames(
                      currentUrl.includes('/jobs')
                        ? styles['selectedButtonImg']
                        : styles['buttonImg']
                    )}
                  />
                </Link>
              </Button>
              <p className={styles.navbarButtonText}>Jobs</p>
            </div>

            <NotificationButton />

            <Dropdown
              className={styles.profileContextMenu}
              isOpen={show}
              toggle={() => {
                setShow(!show);
              }}
              onBlur={() => setIsFaded(false)}
              direction="down"
            >
              <DropdownToggle
                onClick={() => {
                  setShow(true);
                  setIsFaded(true);
                }}
              >
                <Button color="transparent" className={styles['profile-btn']}>
                  <img
                    src={
                      user?.picture?.path
                        ? resolveBEAsset(user?.picture?.path || '')
                        : '/images/defaultProfilePic.svg'
                    }
                    alt="user profile - White Collar Professional"
                  />
                </Button>
              </DropdownToggle>
              <DropdownMenu>
                {!token && (
                  <>
                    <DropdownItem
                      onClick={() => setSignupModal((prev) => !prev)}
                    >
                      <FontAwesomeIcon icon={faUserPlus} />
                      &nbsp;&nbsp;Login/SignUp
                    </DropdownItem>
                  </>
                )}
                {token && (
                  <DropdownItem
                    onClick={() => router(`/profile?profileId=${user?._id}`)}
                  >
                    <FontAwesomeIcon icon={faUser} />
                    &nbsp;&nbsp;My Profile
                  </DropdownItem>
                )}
                <DropdownItem>
                  <a
                    href="/technicalsupport/"
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    <img
                      src="/images/help-support.svg"
                      alt="technicalSupportIcon - White Collar Professional"
                    />
                    &nbsp; Help & Support
                  </a>
                </DropdownItem>
                <DropdownItem>
                  <a
                    href="https://www.whitecollarprofessional.com/legal-policies-wcp_community/"
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    <img
                      src="/images/legal-policy.svg"
                      alt="legalpolicyIcon - White Collar Professional"
                    />
                    &nbsp; Legal Policy
                  </a>
                </DropdownItem>
                {token && (
                  <>
                    <DropdownItem onClick={() => setShowDeleteAccount(true)}>
                      <FontAwesomeIcon icon={faTrash} />
                      &nbsp;&nbsp;Delete Account
                    </DropdownItem>
                    <DropdownItem onClick={() => setShowChangePassword(true)}>
                      <FontAwesomeIcon icon={faLock} />
                      &nbsp;&nbsp;Change Password
                    </DropdownItem>
                    <DropdownItem onClick={() => onLogout()}>
                      <FontAwesomeIcon icon={faPowerOff} />
                      &nbsp;&nbsp;Logout
                    </DropdownItem>
                  </>
                )}
                {!token && (
                  <DropdownItem
                    onClick={() => {
                      router('/');
                    }}
                  >
                    <FontAwesomeIcon icon={faSignOut} />
                    &nbsp;&nbsp;&nbsp;Exit
                  </DropdownItem>
                )}
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
      )}

      {showChangePassword && (
        <ChangePasswordForm
          show={showChangePassword}
          onHide={() => setShowChangePassword(false)}
        />
      )}
      {showDeleteAccount && (
        <DeleteAccount
          show={showDeleteAccount}
          onHide={() => setShowDeleteAccount(false)}
        />
      )}
      {isOpen && <JobsModal isOpen={isOpen} setIsOpen={setIsOpen} />}
      <SignupModal signupModal={signupModal} setSignupModal={setSignupModal} />

      {showChangePassword && (
        <ChangePasswordForm
          show={showChangePassword}
          onHide={() => setShowChangePassword(false)}
        />
      )}
      {showDeleteAccount && (
        <DeleteAccount
          show={showDeleteAccount}
          onHide={() => setShowDeleteAccount(false)}
        />
      )}
      <UpgradeToProfessional
        show={showUpgradeModal}
        setShow={setShowUpgradeModal}
      />
    </>
  );
};

export default Navbar;
