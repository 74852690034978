import { Fragment, useContext, useEffect, useState } from 'react';
import { Accordion, AccordionHeader, AccordionItem } from 'reactstrap';
import { Modal, ModalBody } from 'react-bootstrap';
import { MenuContext } from 'src/context/menu.context';
import { formatMenu } from 'src/components/sidebar/sidebar.helper';
import { IMenuList } from 'src/components/sidebar/sidebar.interface';
import styles from 'src/components/sidebar/sidebar.module.css';
import { faDiamond } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { resolveBEAsset } from 'src/utils/urls';
import { SEARCH_TYPE } from 'src/config/enums';
import { Button } from 'react-bootstrap';
import SignupModal from 'src/components/stepper/SignupModal';
import classNames from 'classnames';

type Props = {
  isOpen: any;
  setIsOpen: any;
  handleCreateSignature: any;
};

const DashboardCategory = ({
  isOpen,
  setIsOpen,
  handleCreateSignature,
}: Props) => {
  const currentUrl = window.location.href;
  const [menuList, setMenuList] = useState<IMenuList[]>([]);

  const { menu } = useContext(MenuContext);
  const [searchParams] = useSearchParams();
  const selectedMetaDataId = searchParams.get('metaDataId');
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const [signupModal, setSignupModal] = useState(false);
  const handleMenuSelect = (
    id: string,
    key: 'metaDataId' | 'subMetaDataId' = 'metaDataId'
  ): void => {
    const urlParams = new URLSearchParams(searchParams);
    urlParams.delete('metaDataId');
    urlParams.delete('subMetaDataId');
    if (selectedMetaDataId?.includes(id)) {
      urlParams.delete('metaDataId');
    } else {
      urlParams.set(key, id);
    }
    if (currentUrl.includes('post') || currentUrl.includes('events')) {
      urlParams.delete('postId');
      urlParams.delete('searchType');
      urlParams.delete('query');
      navigate({
        pathname: '/dashboard',
        search: createSearchParams(urlParams).toString(),
      });
    } else {
      navigate({
        search: createSearchParams(urlParams).toString(),
      });
    }
  };

  const [isSlidingIn, setIsSlidingIn] = useState(false);
  const [isSlidingOut, setIsSlidingOut] = useState(false);
  useEffect(() => {
    if (isOpen) {
      setIsSlidingIn(true);
      setIsSlidingOut(false);
    } else if (!isOpen && isSlidingIn) {
      setIsSlidingOut(true);
      setTimeout(() => setIsOpen(false), 300); // Wait for the sliding-out animation to complete
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleClose = () => {
    if (window.innerWidth < 700) {
      const urlParams = new URLSearchParams(searchParams);
      urlParams.delete('isModal');
      navigate(`?${urlParams.toString()}`);
    }
    setIsSlidingIn(false);
    setTimeout(() => setIsOpen(false), 300);
  };

  useEffect(() => {
    if (menu?.length) {
      setMenuList(formatMenu(menu));
    }
  }, [menu]);

  const [open, setOpen] = useState('');
  const toggle = (id: string) => {
    if (open === id) {
      setOpen('');
    } else {
      setOpen(id);
    }
  };

  return (
    <>
      <Modal
        show={isOpen}
        onHide={handleClose}
        className={classNames(
          isSlidingIn
            ? styles['dashboardCategoryModalOpen']
            : isSlidingOut
            ? styles['dashboardCategoryModalClose']
            : styles['dashboardCategoryModal']
        )}
      >
        <ModalBody>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
            onClick={handleClose}
          >
            <img src="/images/close-btn.svg" alt="" width={20} height={20} />
          </div>
          <Fragment>
            {currentUrl.includes('dashboard') ? (
              <div className={styles['sidebar-option']}>
                <Button
                  style={{
                    marginTop: '-15px',
                    backgroundColor: '#1a1a1a',
                    color: '#fff',
                    background: '#1a1a1a',
                  }}
                  className={styles['create-sign-btn']}
                  onClick={() => {
                    !token ? setSignupModal(true) : handleCreateSignature();
                  }}
                >
                  + Create Category
                </Button>
              </div>
            ) : (
              <div
                className={styles['sidebar-option']}
                style={{
                  marginTop: '-15px',
                  fontSize: '18px',
                  fontWeight: '700',
                  marginLeft: '1rem',
                  marginBottom: '14px',
                }}
              >
                {searchParams.get('searchType') === SEARCH_TYPE.PROFESSIONAL ||
                currentUrl.includes('post') ||
                currentUrl.includes('events') ? (
                  <>DASHBOARD FILTERS</>
                ) : (
                  <>FILTERS</>
                )}
              </div>
            )}
            <hr style={{ width: '100%', margin: '20px 0 3px 0' }} />
            <div className={styles['sidebar-list']}>
              {menuList.map((item, index) => (
                <Accordion
                  className={styles['wc-accordion']}
                  open={open}
                  // @ts-ignore
                  toggle={toggle}
                  key={`item_${index}`}
                >
                  <AccordionItem className={styles['wc-accordion-item']}>
                    <AccordionHeader
                      onClick={() => {
                        if (item.children?.length === 0) {
                          handleMenuSelect(item.id);
                          window.scrollTo(0, 0);
                        }
                      }}
                      className={styles['wc-accordion-header']}
                      targetId={`item_${index}`}
                    >
                      {item.icon && (
                        <img
                          src={resolveBEAsset(item.icon.path)}
                          className={styles['list-icon']}
                          alt="Icon"
                        />
                      )}
                      {item.title}
                      {selectedMetaDataId === item.id && (
                        <span className={styles.floatingIcon}>
                          <FontAwesomeIcon size="sm" icon={faDiamond} />
                        </span>
                      )}
                    </AccordionHeader>
                  </AccordionItem>
                </Accordion>
              ))}
            </div>
          </Fragment>
        </ModalBody>
      </Modal>
      <SignupModal signupModal={signupModal} setSignupModal={setSignupModal} />
    </>
  );
};

export default DashboardCategory;
