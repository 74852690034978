import { useEffect, useState, useContext } from 'react';
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import DashboardListItem from './listItem';
import classNames from 'classnames';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { LoadingLottie } from 'src/components/lotties/loading';
import NoData from 'src/components/no-data';
import { POST_FILTER, postFilterArr } from 'src/config/enums';
import {
  fetchAllPostPins,
  fetchAllPostUpvotes,
  getAllPost,
} from 'src/services/post-service/post-service';
import { IPost } from 'src/services/post-service/post-service.interface';
import styles from './dashboardList.module.css';
import BottomBar from 'src/components/bottombar/BottomBar';
import DashboardCategory from './DashboardCategory';
import CreateSignatureModal from 'src/components/createSignature/CreateSignatureModal';
import CategoryRequestSentModal from 'src/components/createSignature/CategoryRequestSentModal';
import { MenuContext } from 'src/context/menu.context';
import { IMenuList } from 'src/components/sidebar/sidebar.interface';
import { formatMenu } from 'src/components/sidebar/sidebar.helper';
import SignupModal from 'src/components/stepper/SignupModal';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DashboardList = ({
  refresh,
  profilePage = false,
}: {
  refresh?: IPost;
  profilePage?: boolean;
}) => {
  const user = JSON.parse(localStorage.getItem('user') || 'null');
  const [filterType, setFilterType] = useState<POST_FILTER>('All');
  const [pageLimit] = useState<number>(20);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [dummyTrigger, setDummyTrigger] = useState<number>(1);
  const [postList, setPostList] = useState<IPost[]>([]);
  const [loading, setLoading] = useState(false);
  const [newSearch, setNewSearch] = useState(true);
  const [postsCompleted, setPostsCompleted] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const metaDataId = searchParams.get('metaDataId') || '';
  const subMetaDataId = searchParams.get('subMetaDataId') || '';
  const searchTerm = searchParams.get('searchQuery') || '';
  const view = searchParams.get('view') || '';
  const profileId = searchParams.get('profileId') || '';
  const searchType = searchParams.get('searchType') || '';
  const [showDropdown, setShowDropdown] = useState(false);
  const [isCategory, setIsCategory] = useState(false);

  const showCategory = () => {
    if (window.innerWidth < 700) {
      const urlParams = new URLSearchParams(searchParams);
      urlParams.set('isModal', 'open');
      navigate(`?${urlParams.toString()}`);
    }
    setIsCategory(true);
  };

  const [isCreateModalOpen, setCreateModalOpen] = useState(false);
  const [isCategoryRequestSentModalOpen, setCategoryRequestSentModalOpen] =
    useState(false);
  const handleCreateSignature = () => {
    setIsCategory(false);
    window.scrollTo(0, 0);
    setCreateModalOpen(true);
  };

  const handleCreateSignatureSuccess = () => {
    setCreateModalOpen(false);
    setCategoryRequestSentModalOpen(true);
  };

  const currentUrl = window.location.href;
  const { menu } = useContext(MenuContext);
  const [menuList, setMenuList] = useState<IMenuList[]>([]);

  useEffect(() => {
    if (menu?.length) {
      setMenuList(formatMenu(menu));
    }
  }, [menu]);
  const selectedMetaDataId = searchParams.get('metaDataId');
  const selectedMetaDataTitle = Object.values(menuList).find(
    (obj) => obj.id === selectedMetaDataId
  );
  const title = selectedMetaDataTitle?.title;
  const navigate = useNavigate();

  const token = localStorage.getItem('token');

  const [signupModal, setSignupModal] = useState(false);

  useEffect(() => {
    setPageNumber(1);
    setPostsCompleted(false);
    setNewSearch(true);
    setPostList([]);
    setDummyTrigger((num) => num + 1);
  }, [
    pageLimit,
    filterType,
    refresh,
    metaDataId,
    subMetaDataId,
    searchTerm,
    view,
    profileId,
  ]);

  useEffect(() => {
    if (postsCompleted) {
      return;
    }
    fetchPosts(
      metaDataId,
      subMetaDataId,
      pageLimit,
      filterType,
      pageNumber,
      view as any,
      profileId
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, dummyTrigger]);

  const fetchPosts = async (
    metaDataId: string,
    subMetaDataId: string,
    pageLimit: number,
    filterType: POST_FILTER,
    pageNumber: number,
    view: 'published-works' | 'questions' | 'answers' | 'pins',
    profileId: string
  ) => {
    setLoading(true);

    try {
      const posts = await getAllPost(
        metaDataId,
        subMetaDataId,
        pageLimit,
        filterType,
        pageNumber,
        searchTerm,
        view,
        searchType,
        profileId,
        user
      );

      if (!posts.data.length) {
        setPostsCompleted(true);
      }

      if (posts?.data.length) {
        const newPostList = [...postList, ...posts.data];
        const postIds = newPostList.map((post) => post._id).filter(Boolean);
        let upvotesPostList: any[] = [];
        try {
          const upvotesResponse = await fetchAllPostUpvotes(postIds, 'post');
          const upvotesMap = upvotesResponse.data.data;

          upvotesPostList = newPostList.map((post) => ({
            ...post,
            postUpvotes: upvotesMap[post._id] || [],
          }));
        } catch (upvoteError) {
          console.error('Error fetching post upvotes:', upvoteError);
        }
        try {
          const pinsResponse = await fetchAllPostPins(postIds, user?._id);
          const pinsMap = pinsResponse.data.data;
          const pinsPostList = upvotesPostList.map((post: any) => ({
            ...post,
            postPins: pinsMap[post._id] || [],
          }));

          setPostList(pinsPostList);
        } catch (pinsError) {
          console.error('Error fetching post pins:', pinsError);
        }
      }
    } catch (error) {
      console.error('Error fetching posts:', error);
    } finally {
      setLoading(false);
      setNewSearch(false);
    }
  };

  return (
    <div className={styles['dashboard-list']}>
      {window.innerWidth < 700 ? (
        <div className={styles['dashboard-list-header-mobile']}>
          {currentUrl.includes('dashboard') && (
            <div className={styles['dashboard-mobile-header-wrapper']}>
              <div className={styles.filterCategoryMobileWrapper}>
                <Button
                  onClick={() => {
                    showCategory();
                  }}
                  className={styles.filterCategoryMobileBtn}
                >
                  {title === undefined ? 'Filter by Category' : title}
                  <span
                    style={{
                      fontSize: '9px',
                      marginLeft: '4px',
                    }}
                  >
                    &#9660;
                  </span>
                </Button>
                {currentUrl.includes('/dashboard?metaDataId') && (
                  <img
                    src="/images/close-circle.svg"
                    alt="closeIcon"
                    height={22}
                    onClick={() => {
                      navigate('/dashboard');
                      window.scrollTo(0, 0);
                    }}
                  />
                )}
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  width: '100%',
                }}
              >
                {!view?.length && (
                  <>
                    <Dropdown
                      isOpen={showDropdown}
                      toggle={() => setShowDropdown(!showDropdown)}
                      className={styles['sort-dropdown-mobile']}
                    >
                      <DropdownToggle
                        className={styles['sort-dropdown-toggle-mobile']}
                      >
                        <div
                          onClick={() => {
                            setShowDropdown(true);
                          }}
                        >
                          {filterType === 'All' ? (
                            <>
                              All{' '}
                              <span className={styles.filterTypeSpan}>
                                &#9660;
                              </span>
                            </>
                          ) : (
                            <>
                              {filterType}{' '}
                              <span className={styles.filterTypeSpan}>
                                &#9660;
                              </span>
                            </>
                          )}
                        </div>
                      </DropdownToggle>
                      <DropdownMenu
                        className={styles['sort-dropdown-menu-mobile']}
                      >
                        {postFilterArr?.map((item, idx) => (
                          <>
                            <DropdownItem
                              style={{
                                padding: '0',
                                borderBottom: '1px solid #e6e6e6',
                                width: '100%',
                              }}
                              className={styles['sort-dropdown-item-mobile']}
                              onClick={(e) => {
                                e.preventDefault();
                                setFilterType(item);
                              }}
                            >
                              <Button key={idx} color="transparent">
                                {item}
                              </Button>
                            </DropdownItem>
                          </>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                  </>
                )}
              </div>
            </div>
          )}
          <BottomBar />
        </div>
      ) : (
        <div className={styles['dashboard-list-header-windows']}>
          {!currentUrl.includes('profile') && (
            <div className={styles['dashboard-filter-pagination']}>
              <div className={styles['dashboard-filter']}>
                {!view?.length && (
                  <div className={styles['dashboard-filterBy-wrapper']}>
                    <div className={styles['dashboard-filterBy-hr']} />
                    <div className={styles['dashboard-filterBy-text']}>
                      <p>Show Results</p>
                      {postFilterArr.map((item, idx) => (
                        <Button
                          key={item}
                          color="transparent"
                          className={classNames(
                            styles['filter-btn'],
                            item === filterType ? styles['selected'] : ''
                          )}
                          onClick={() => setFilterType(item)}
                        >
                          {item}
                        </Button>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )}

      {newSearch ? (
        <LoadingLottie height="400px" />
      ) : !newSearch && !postList.length ? (
        <NoData />
      ) : (
        <>
          {postList.map((item, idx) => (
            <DashboardListItem
              profilePage={profilePage}
              data={item}
              key={`DashboardItem_${idx}`}
            />
          ))}
          {loading ? (
            <LoadingLottie height="150px" />
          ) : (
            postList && (
              <div className="text-center">
                <div className={styles['load-more']}>
                  {postsCompleted ? (
                    <>
                      You're all caught up! &nbsp;
                      <Button
                        className={styles['topScroll']}
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        Go to Top
                      </Button>
                    </>
                  ) : (
                    <Button
                      color="transparent"
                      className={styles.loadMoreBtn}
                      onClick={() =>
                        !token
                          ? setSignupModal(true)
                          : setPageNumber((prevPage) => prevPage + 1)
                      }
                    >
                      <FontAwesomeIcon
                        icon={faChevronDown}
                        style={{ color: '#1a1a1a' }}
                      />
                    </Button>
                  )}
                </div>
              </div>
            )
          )}
        </>
      )}
      {isCategory && (
        <DashboardCategory
          isOpen={isCategory}
          setIsOpen={setIsCategory}
          handleCreateSignature={handleCreateSignature}
        />
      )}
      <CreateSignatureModal
        isOpen={isCreateModalOpen}
        onClose={() => {
          setCreateModalOpen(false);
        }}
        onCreate={handleCreateSignatureSuccess}
      />

      <CategoryRequestSentModal
        isOpen={isCategoryRequestSentModalOpen}
        onClose={() => {
          setCategoryRequestSentModalOpen(false);
        }}
      />
      <SignupModal signupModal={signupModal} setSignupModal={setSignupModal} />
    </div>
  );
};

export default DashboardList;
