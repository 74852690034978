import React, { useEffect, useRef, useState } from 'react';
import { uploadAttachment } from 'src/services/attachment-service/attachment-service.client';
import { createTrend } from 'src/services/trend-service/trend-service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faClock } from '@fortawesome/free-solid-svg-icons';
import styles from '../../pages/trend/trend.module.css';
import { dataUrlToFile, resolveBEAsset } from 'src/utils/urls';
import Modal from 'react-bootstrap/Modal';
import ReactQuill from 'react-quill';
import { IUser } from 'src/services/user-service/user-service.interface';
import { FileList } from 'src/components/file-list';
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import { fetchUserDetails } from 'src/services/user-service/user-servicec';
import SignupModal from 'src/components/stepper/SignupModal';
import { formats, modules, resizeImage } from './trendHelper';
import EventLogo from 'src/components/events/EventLogo';
import { useNavigate } from 'react-router-dom';

export const CreateTrend = () => {
  const [charCount, setCharCount] = useState(0);
  const maxChars = 1024;
  const [charExceeds, setCharExceeds] = useState(false);
  const [trendTimer, setTrendTimer] = useState(24);
  const [timerDropdown, setTimerDropdown] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<Array<File>>([]);
  const [desc, setDesc] = useState('');
  const [imageSelected, setImageSelected] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const token = localStorage.getItem('token');
  const [user, setUser] = useState<IUser | null>(null);
  const id = localStorage.getItem('id');
  const [signupModal, setSignupModal] = useState(false);

  const getUserDetails = async (userId: string) => {
    try {
      const userRes = await fetchUserDetails(userId);
      setUser(userRes);
    } catch (error) {
      console.error('Failed to fetch user details:', error);
    }
  };
  useEffect(() => {
    if (id) {
      getUserDetails(id);
    }
  }, [id]);
  const attchmentList = [
    { icon: '/images/imageIcon.svg', text: 'Image', accept: 'image/*' },
    { icon: '/images/pdfIcon.svg', text: 'PDF', accept: '.pdf' },
  ];

  const isLoggedIn = () => {
    if (token) {
      setModalShow(true);
    } else {
      setSignupModal(true);
    }
  };

  const handleImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    if (files && files.length) {
      const file = files[0];
      if (selectedFiles.length > 0) {
        return setImageSelected(true);
      }

      if (file.type.startsWith('image/')) {
        try {
          const resizedDataUrl = await resizeImage(file, 1024);
          const resizedFile = await dataUrlToFile(resizedDataUrl, file.name);
          setSelectedFiles((prevFiles) => [...prevFiles, resizedFile]);
          // setImageSelected(true);
        } catch (error) {
          console.error('Error resizing image:', error);
        }
      } else if (file.type === 'application/pdf') {
        setSelectedFiles((prevFiles) => [...prevFiles, file]);
      }
    }
  };

  function deSelectAttachment(fileIndex: number): void {
    setImageSelected(false);

    setSelectedFiles([
      ...selectedFiles.filter((_, index) => index !== fileIndex),
    ]);
  }

  const inputRefs = useRef(
    attchmentList.map(() => React.createRef<HTMLInputElement>())
  );

  const handleButtonClick = (index: number) => {
    if (inputRefs.current[index]) {
      inputRefs?.current[index]?.current?.click();
    }
  };
  const resetForm = () => {
    setDesc('');
    setSelectedFiles([]);
  };
  const navigate = useNavigate();
  const handleSubmit = async () => {
    if (desc.length === 0 && selectedFiles.length === 0) {
      alert('Please add a description or attach a file.');
      return;
    }

    try {
      const attachmentsArr = await uploadAttachment(selectedFiles);
      await createTrend(attachmentsArr, desc, trendTimer);

      const urlParams = new URLSearchParams(window.location.search);
      urlParams.set('trendCreated', `true`);
      navigate(`/trending?${urlParams.toString()}`);
      setModalShow(false);
      resetForm();
    } catch (e) {
      console.log('Trend creation failed');
      resetForm();
    }
  };
  useEffect(() => {
    const plainText = desc.replace(/<\/?[^>]+(>|$)/g, '');
    setCharCount(plainText.length);
    setCharExceeds(plainText.length > maxChars);
  }, [desc]);

  const handleDescChange = (value: any) => {
    setDesc(value);
  };

  useEffect(() => {
    if (selectedFiles.length === 0) {
      setImageSelected(false);
    }
  }, [selectedFiles]);
  return (
    <>
      <div className={styles['create-trend']}>
        {window.innerWidth > 700 ? (
          <Button
            className={styles['create-trend-btn']}
            variant="secondary"
            size="lg"
            onClick={() => {
              !token ? setSignupModal(true) : isLoggedIn();
            }}
          >
            <EventLogo fill="var(--grey-grey-00, #FFF)" /> &nbsp; Create Trend
          </Button>
        ) : (
          <div
            onClick={() => {
              !token ? setSignupModal(true) : isLoggedIn();
            }}
            className={styles['create-trend-mobile-wrapper']}
          >
            {' '}
            <Button className={styles['create-trend-btn-mobile']}>
              <EventLogo fill="var(--grey-grey-00, #FFF)" />
            </Button>
          </div>
        )}
      </div>

      <Modal
        show={modalShow}
        centered
        onHide={() => {
          setModalShow(false);
        }}
        toggle={() => setModalShow((prev) => !prev)}
        className={styles['createTrendModal']}
      >
        <Modal.Header
          closeButton
          style={{ borderBottom: 'none', padding: '12px 16px 0' }}
        >
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            {user && (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src={
                    user?.picture
                      ? resolveBEAsset(user?.picture?.path || '')
                      : '/images/defaultProfilePic.svg'
                  }
                  className={styles.userPicture}
                  alt="userImage"
                />
                &nbsp;{' '}
                <Dropdown
                  isOpen={timerDropdown}
                  toggle={() => setTimerDropdown(!timerDropdown)}
                  className={styles['sort-dropdown-mobile']}
                >
                  <DropdownToggle
                    style={{
                      display: 'flex',
                      background: 'none',
                      color: 'black',
                      alignItems: 'center',
                      border: 'none',
                      fontSize: '14px',
                    }}
                  >
                    <Button
                      onClick={() => {
                        setTimerDropdown(true);
                      }}
                      className={styles['noit-professional-only']}
                    >
                      {trendTimer === 24 ? (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <FontAwesomeIcon icon={faClock} />
                          &nbsp; 24 hours &nbsp;{' '}
                          <FontAwesomeIcon icon={faCaretDown} />
                        </div>
                      ) : (
                        <>
                          <FontAwesomeIcon icon={faClock} />
                          &nbsp; 48 hours &nbsp;{' '}
                          <FontAwesomeIcon icon={faCaretDown} />
                        </>
                      )}
                    </Button>
                  </DropdownToggle>
                  <DropdownMenu>
                    <>
                      <DropdownItem
                        onClick={() => setTrendTimer(24)}
                        style={{
                          whiteSpace: 'break-spaces',
                          lineHeight: 'initial',
                        }}
                      >
                        <span
                          style={{
                            fontSize: '12px',
                            fontWeight: '500',
                            textDecoration: 'underline',
                          }}
                        >
                          24 hours
                        </span>
                        <br />
                        <span style={{ fontSize: '12px' }}>
                          This trend shall be available for 24 hours.
                        </span>
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => setTrendTimer(48)}
                        style={{
                          whiteSpace: 'break-spaces',
                          lineHeight: 'initial',
                        }}
                      >
                        <span
                          style={{
                            fontSize: '12px',
                            fontWeight: '500',
                            textDecoration: 'underline',
                          }}
                        >
                          48 hours
                        </span>
                        <br />
                        <span style={{ fontSize: '12px' }}>
                          This trend shall be available for 48 hours.
                        </span>
                      </DropdownItem>
                    </>
                  </DropdownMenu>
                </Dropdown>
              </div>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: '0 16px 16px' }}>
          {' '}
          <ReactQuill
            theme="snow"
            value={desc}
            formats={formats}
            modules={modules}
            onChange={handleDescChange}
            className={styles['text-editor-quill']}
            placeholder="Enter your Trends Text"
          />{' '}
          <div
            className={`${styles['char-count']} ${
              charExceeds ? styles['char-count-exceed'] : ''
            }`}
          >
            {charCount} / {maxChars} characters
          </div>
          {selectedFiles.length > 0 && (
            <FileList
              files={selectedFiles || []}
              onClose={(index) => deSelectAttachment(index)}
            />
          )}
          {imageSelected && (
            <div style={{ fontSize: '10px', color: 'red', marginTop: '-8px' }}>
              You can only upload one attachment when posting a trend.
            </div>
          )}
          <div
            className={styles['dashboard-search-attachment-list']}
            style={{
              justifyContent: 'space-between',
              padding: '0 12px',
              marginTop: '12px',
            }}
          >
            <div className={styles.attachmentWrapper}>
              {' '}
              {attchmentList.map((item, idx) => (
                <Button
                  key={`Attachment_${item.text}`}
                  className={styles['attachment-btn']}
                  color="transparent"
                  onClick={() => handleButtonClick(idx)}
                >
                  <input
                    type="file"
                    accept={`${item.accept}`}
                    style={{ display: 'none' }}
                    ref={inputRefs.current[idx]}
                    onChange={handleImageChange}
                  />
                  <img src={item.icon} alt={item.text} />
                  {item.text}
                </Button>
              ))}
            </div>

            <button
              onClick={handleSubmit}
              disabled={charExceeds}
              className={styles['postBtn']}
            >
              Post Trend
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <SignupModal signupModal={signupModal} setSignupModal={setSignupModal} />
    </>
  );
};
